import React, { useEffect, useRef } from 'react';
import * as typeformEmbed from '@typeform/embed';
import useDatalayer from '../../../../hooks/use-datalayer';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';

export const WorkForUgoForm = () => {
  const typeformRef = useRef(null);
  const datalayer = useDatalayer();
  const analytics = useAnalytics();
  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      process.env.GATSBY_TYPEFORM_DRIVER_DOF_ENTRY_FORM_URI,
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 50,
        onSubmit: () => {
          datalayer.triggerJobApplicationSubmittedDriver();
          analytics.submitEvent<'ja-submitted-d'>('ja-submitted-d', {
            location: 'm-jaf-typeform_submit_hook',
          });
        },
      }
    );
  }, [typeformRef]);

  return (
    <div
      ref={typeformRef}
      style={{ height: 'calc(100vh - 110px)', background: 'white' }}
    ></div>
  );
};
