import SEO from '../components/core/meta/SEO';
import React from 'react';
import { WorkForUgoForm } from '../components/partials/WorkForUgoForm/WorkForUgoForm';

export default function LavoraConUgoFormPage() {
  return (
    <div tw="space-y-16">
      <SEO
        title="Lavora con ugo"
        description="Il servizio UGO sta ricercando caregiver professionali.Candidati e lavora subito per affiancare persone anziane e fragili nelle attività quotidiane"
        hidePageSuffix
        url={`/lavora-con-ugo-form/`}
      />
      <section>
        <div>{typeof window != 'undefined' && <WorkForUgoForm />}</div>
      </section>
    </div>
  );
}
